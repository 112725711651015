

import widget from './widget.js'
export default {
    props: 
    {
      module: 
      {
        type: Object,
        default:{}
      }
    },
    components: {
    },
    mixins: [widget],
    data () {
      return {
        moduleManufacturerName : this.module.device.brandObj.manufacturer.displayName,
        moduleBrandLogoPath : this.module.device.brandObj.imagePath,
        moduleBrandName : this.module.device.brandObj.displayName,
        moduleModelName: this.module.device.submodel,
        moduleZoneName: this.$gettext(this.module.zoneName),
        moduleIconClass: (this.module.icon?'cl-icon cl-icon-'+this.module.icon+'-default':''),
        moduleName:this.module.name,
      }
    },
    created: function(){
    },
    methods: {
  	}
}
