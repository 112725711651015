/**
 * VueJS file to manage the different interactions of the component
 * 
 *
 * => VueJS predefined datas :
 *  
 *  The predefined datas can be directly used with, for example, this mustache syntax in "widget.vue": {{[PREDEFINED DATA]}} (ex: {{moduleName}})
 *  
 *  -> moduleName: Module name
 *  -> moduleIconClass: The class of the icon to insert in the class attribute of the HTML tag (ex: <span :class="moduleIconClass"></span>) 
 *  -> moduleZoneName: Zone name
 *  -> moduleManufacturerName: Manufacturer name
 *  -> moduleBrandLogoPath: Brand logo path
 *  -> moduleBrandName: Brand name
 *  -> moduleModelName: Model name
 * 
 * 
 * => Events
 * 
 * Here is the syntax to emit a event : this.$emit([EVENT NAME],[EVENT VALUE]);
 * 
 * --------------------
 * Event that emits the change of the device status with the capability and the value
 * EVENT NAME : 'control'
 * EVENT VALUE : Object
 * {
    "capability" : string
    "value" : string
   } 
 * --------------------
 *
 * => Methods
 * 
 * These methods communicate directly with the app.
 * 
 * Syntax:
 * methods: {
    [METHOD NAME] : function([@PARAM]){
      //Custom lines of code for the component
    },
  }
 * --------------------
 * Method called when device control was successful
 * METHOD NAME : onControlCallBack
 * PARAM : callbackObj (Object)
 * --------------------
 * 
 */

//DO NOT REMOVE this line
export default {
    //VueJs data
    data () {
      return {
        //Sample data that can be deleted
        successControl: false
      }
    },
    created : function(){
      //Add functions to call when the widget is created
    },
    methods: {
      /**
       * Example of method
       * 
       */
      onClick : function(status){
      /**
       * EVENT : 'control'
       * 
       */
        this.$emit('control',{'capability': 'status','value': status});
      },
      /**
       * METHOD : Method called when device control was successful
       * @param {Object} callbackObj : callback object 
       */
      onControlCallBack : function(callbackObj){
        //Sample code that can be deleted
        this.successControl = true;
        //Custom lines of code for the component
        //.....
      },
  	}
}
